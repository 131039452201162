<template>
    <div>
        <v-container>
            <!-- 消息条 -->
            <v-snackbar :timeout="1500" class="mt-4" v-model="notiBar.enabled" top transition="slide-y-transition"
                :color="notiBar.color" center text style="top: 56px">
                {{ notiBar.text }}
            </v-snackbar>

            <!-- 客户端列表区域 -->
            <v-card class="my-3">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h6 pl-0"><v-btn depressed to="/"
                            class="mr-5"><v-icon>mdi-arrow-left-thick</v-icon>返回</v-btn>用户群组列表
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="refresh" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
                    <v-btn depressed @click="createCode" color="primary" class="mx-2"><v-icon>mdi-plus</v-icon></v-btn>
                </v-app-bar>
            </v-card>

            <v-card class="mx-auto pa-5 mt-5">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h6 pl-0">用户群组列表
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="搜索" single-line
                        hide-details></v-text-field>
                </v-app-bar>

                <!-- 表格区 -->
                <v-card-text>
                    <v-data-table :headers="headers" :items="list" :search="search" :items-per-page="100" :loading="loading"
                        loading-text="正在获取数据。。。" :calculate-widths="false">

                        <!-- own_by的展示 -->
                        <template v-slot:item.own_by="{ item }">
                            {{ show_group_name(item.own_by) }}
                        </template>
                        <!-- own_by的展示 -->

                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editCode(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteCode(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                </v-card-text>
            </v-card>

            <!-- 编辑框 -->
            <v-dialog v-model="editor.dialog_open" persistent>
                <v-card>
                    <v-card-title>{{
                        editor.mode == "edit" ? "修改用户群组信息" : "新建用户群组"
                    }}</v-card-title>
                    <v-card-text>
                        <!-- 编辑框主体部分 -->
                        <v-row>
                            <v-col><v-text-field label="群组名称" outlined v-model="editor.data.name"></v-text-field></v-col>
                            <v-col>
                                <v-select v-model="editor.ownby_select" :hint="editor.ownby_select.name" :items="list"
                                    item-text="name" item-value="id" label="群组归属" persistent-hint return-object
                                    outlined></v-select>
                            </v-col>
                        </v-row>
                        <div class="text">当前群组ID:{{ this.editor.data.id }}</div>
                        <!-- 编辑框主体部分 -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary darken-1" text @click="submit">
                            {{ editor.mode == "edit" ? "提交修改" : "创建" }}
                        </v-btn>
                        <v-btn color="red darken-1" text @click="editor.dialog_open = false">
                            关闭
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- 编辑框 -->

            <!-- 提示框 -->
            <v-dialog v-model="alert.alert" max-width="450px">
                <v-card>
                    <v-card-title>{{ alert.alert_type }}</v-card-title>
                    <v-card-text>
                        {{ alert.alert_text }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="alert.alert = !alert.alert">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- 删除提示框 -->
            <v-dialog v-model="deleteData.dialog" max-width="450px">
                <v-card>
                    <v-card-title>确认删除</v-card-title>
                    <v-card-text> 请再次确认？ </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="doDelete">删除</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteData.dialog = false">取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-container>
    </div>
</template>
  
<script>
export default {
    name: "adminuserGroup",
    data: function () {
        return {
            search: "",
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                },
                { text: "名称", value: "name" },
                { text: "归属", value: "own_by" },
                { text: "用户数量", value: "user_num" },
                { text: '操作', value: 'actions', sortable: false },
            ],
            list: [],
            group_name_map: null,
            editor: {
                mode: "",
                dialog_open: false,
                data: {
                    id: 1,
                    name: "超级管理员",
                    own_by: 0,
                    user_num: 0
                },
                ownby_select: {},
            },
            loading: false,
            alert: {
                alert_text: "",
                alert_type: "info",
                alert: false,
            },
            notiBar: {
                enabled: false,
                color: "",
                text: "",
            },
            deleteData: {
                dialog: false,
                id: 0,
            },
            delete_item: null
        };
    },
    mounted: function () {
        this.initData();
    },
    watch: {

    },
    computed: {
        editor_ownby_noti: function () {
            return this.show_group_name(this.editor.data.own_by)
        }
    },
    beforeDestroy() {

    },
    methods: {
        initData: function () {
            this.loading = true;
            var _this = this;
            //获取数据
            this.axios
                .get("/api/admin/listUserGroup")
                .then((res) => {
                    if (res.data.status === 0) {
                        // console.log(res.data.data.stats);
                        this.list = res.data.data.list;

                        this.group_name_map = new Map();
                        for (var group of _this.list) {
                            console.log(group);
                            _this.group_name_map.set(group.id, group.name);
                        }
                        this.editor.ownby_select = this.list[0];

                        console.log("this.editor.ownby_select", this.editor.ownby_select);
                    } else {
                        this.errorBar(this.parseError(res));
                    }
                    this.loading = false;


                    return;
                })
                .catch((err) => {
                    console.log(err);
                    this.error("发生错误:" + err);
                });
        },
        submit: function () {
            this.editor.data.own_by = Number(this.editor.data.own_by);
            //发送
            this.axios({
                method: "post",
                url: "/api/admin/editUserGroup",
                data: {
                    edit_type: this.editor.mode,
                    data: {
                        id: this.editor.data.id,
                        name:this.editor.data.name,
                        own_by: this.editor.ownby_select.id,
                    }
                },
            })
                .then((res) => {
                    if (res.data.status === 0) {
                        this.editor.dialog_open = false;
                        this.successBar("操作成功");
                        this.initData();
                    } else {
                        this.error(this.parseError(res));
                    }
                    return;
                })
                .catch((err) => {
                    console.log(err);
                    this.error("发生错误:" + err);
                });
        },
        createCode: function () {
            this.editor.mode = "add";
            this.editor.data = {}
            this.editor.dialog_open = true;
        },
        editCode: function (item) {
            console.log("editCode", item);
            this.editor.mode = "edit";
            this.editor.data = this.copy(item);
            this.editor.dialog_open = true;
        },
        deleteCode: function (item) {
            this.deleteData.dialog = true;
            this.deleteData.id = item.id;
        },
        doDelete: function () {
            this.axios({
                method: "post",
                url: "/api/admin/delUserGroup",
                data: {
                    id: this.deleteData.id
                },
            })
                .then((res) => {
                    if (res.data.status === 0) {
                        this.successBar("删除成功");
                        this.initData();
                    } else {
                        this.error(this.parseError(res));
                    }
                    this.deleteData.dialog = false;
                    return;
                })
                .catch((err) => {
                    console.log(err);
                    this.error("发生错误:" + err);
                });
        },
        show_group_name: function (data) {
            if (data == 0) {
                return "系统"
            }
            var out = this.group_name_map.get(Number(data));
            if (out == null) {
                return "NA"
            }
            return ["【", data, "】", out].join("");
        },
        copy: function (data) {
            var objString = JSON.stringify(data);
            return JSON.parse(objString);
        },
        refresh: function () {
            this.initData();
            this.successBar("刷新成功");
        },
        success: function (text) {
            this.alert.alert = !this.alert.alert;
            this.alert.alert_text = text;
            this.alert.alert_type = "成功";
        },
        error: function (err) {
            this.alert.alert = !this.alert.alert;
            this.alert.alert_text = err;
            this.alert.alert_type = "错误";
        },
        successBar: function (text) {
            this.notiBar.enabled = !this.notiBar.enabled;
            this.notiBar.text = text;
            this.notiBar.color = "success";
        },
        errorBar: function (err) {
            this.notiBar.enabled = !this.notiBar.enabled;
            this.notiBar.text = err;
            this.notiBar.color = "error";
        },
    },
};
</script>
  
<style></style>
  